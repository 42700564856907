import React from "react";
import { Menu } from "antd";
import { AppLayout } from "components/ContainerComponents/AppLayout";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const {
  HeaderContainer,
  HeaderContent,
  AppLogo,
  NavContainer,
  NavDropdown,
  HeaderDropdown,
} = AppLayout;

const AppHeader = ({ authUser, userSignOut }) => {
  const handleSignOut = () => {
    userSignOut();
  };

  const userMenuOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/change-password">Alterar senha</Link>
      </Menu.Item>
      <Menu.Item onClick={handleSignOut}>Sair</Menu.Item>
    </Menu>
  );

  const dashboardOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/">CRM</Link>
      </Menu.Item>
    </Menu>
  );

  const adminOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/management/users">Usuários</Link>
      </Menu.Item>
    </Menu>
  );

  const crmdOptions = (
    <Menu>
      <Menu.Item>
        <Link to="/crm/customers">Clientes</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/crm/orders">Pedidos de Cotação</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/crm/client-app">Portal do Cliente</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <AppLogo
            src={require("assets/images/mutuus-logo.png")}
            alt="Mutuus Seguros - logomarca"
          />
          <HeaderDropdown menu={userMenuOptions}>
            {authUser?.name ? authUser?.name : "Minha Conta"}
          </HeaderDropdown>
        </HeaderContent>
      </HeaderContainer>
      <NavContainer>
        <NavDropdown menu={dashboardOptions}>Dashboard</NavDropdown>
        <NavDropdown menu={adminOptions}>Administrativo</NavDropdown>
        <NavDropdown menu={crmdOptions}>CRM</NavDropdown>
      </NavContainer>
    </>
  );
};

export default AppHeader;

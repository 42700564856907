import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "antd";

const UserInfo = ({ userSignOut }) => {
  const handleSignOut = () => {
    userSignOut();
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link to="/change-password">Meu perfil</Link>
      </li>
      <li>
        <Link to="/change-password">Alterar senha</Link>
      </li>
      <li onClick={handleSignOut}>Sair do sistema</li>
    </ul>
  );

  return (
    <Dropdown overlay={userMenuOptions} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        Nome do usuário <Icon type="down" />
      </a>
    </Dropdown>
  );
};

export default UserInfo;

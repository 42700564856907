import React from "react";

import "./styles.less";
import { AutoComplete, Button, Icon, Select } from "antd";

const Container = ({ children }) => {
  return (
    <div className="mu-search-bar__container">
      <div className="mu-search-bar__wrapper gx-container">{children}</div>
    </div>
  );
};

const IconWrapper = ({ children, ...props }) => {
  return (
    <div className="mu-search-bar__container-icon" {...props}>
      {children}
    </div>
  );
};

const Title = ({ children, ...props }) => {
  return (
    <h2 className="mu-search-bar__title" {...props}>
      {children}
    </h2>
  );
};

const TitleWrapper = ({ children }) => {
  return <div className="mu-search-bar__title-wrapper">{children}</div>;
};

const SearchWrapper = ({ children }) => {
  return <div className="mu-search-bar__search-wrapper">{children}</div>;
};

const SearchSelect = ({ selectOptions = [], ...props }) => {
  return (
    <Select className="mu-search-bar__search-select" {...props}>
      {selectOptions?.map((option, index) => (
        <Select.Option
          key={index}
          className="mu-search-bar__search-option"
          value={option.value}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const AutocompleteOption = ({ children, ...props }) => {
  return <AutoComplete.Option {...props}></AutoComplete.Option>;
};

const Autocomplete = ({ ...props }) => {
  return (
    <div className="mu-search-bar__autocomplete">
      <AutoComplete style={{ borderRadius: "0 8px 8px 0" }} {...props} />
    </div>
  );
};

const ActionButton = ({ children, ...props }) => {
  return (
    <div className="mu-search-bar__button">
      <Button type="primary" {...props}>
        <Icon type="plus-circle" style={{ fontSize: 20 }} />
        {children}
      </Button>
    </div>
  );
};

export const SearchBar = {
  Container,
  IconWrapper,
  TitleWrapper,
  Title,
  SearchWrapper,
  SearchSelect,
  Autocomplete,
  AutocompleteOption,
  ActionButton,
};

import React from "react";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";

import "./styles.less";
import { ChevronDown } from "react-feather";

const HeaderContainer = ({ children }) => {
  return (
    <div className="mu-layout__header-container">
      <div className="mu-layout__header-wrapper">{children}</div>
    </div>
  );
};

const HeaderContent = ({ children }) => {
  return (
    <div className="mu-layout__header-content gx-container">{children}</div>
  );
};

const AppLogo = ({ src, alt, to = "/" }) => {
  return (
    <Link to={to} className="">
      <img alt={alt} src={src} />
    </Link>
  );
};

const HeaderDropdown = ({ children, menu }) => {
  return (
    <div className="mu-layout__dropdown-container">
      <Dropdown overlay={menu} trigger={["click"]}>
        <a
          className="ant-dropdown-link mu-layout__dropdown-link"
          style={{ color: "#545454" }}
          onClick={(e) => e.preventDefault()}
        >
          {children}
          <ChevronDown size={20} />
        </a>
      </Dropdown>
    </div>
  );
};

const NavContainer = ({ children }) => {
  return (
    <div className="mu-layout__nav-container">
      <div className="mu-layout__nav-wrapper gx-container">{children}</div>
    </div>
  );
};

const NavDropdown = ({ children, menu }) => {
  return (
    <div className="mu-layout__dropdown-container mu-layout__nav-dropdown-container ">
      <Dropdown overlay={menu} trigger={["click"]}>
        <a
          className="ant-dropdown-link mu-layout__dropdown-link"
          style={{ color: "#fff" }}
          onClick={(e) => e.preventDefault()}
        >
          {children}
          <ChevronDown size={20} />
        </a>
      </Dropdown>
    </div>
  );
};

export const AppLayout = {
  HeaderContainer,
  HeaderContent,
  AppLogo,
  NavContainer,
  NavDropdown,
  HeaderDropdown,
};

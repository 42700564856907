import React from "react";
//Translation
import Translation from "../TermDetails/translation";
import { useTranslation } from "react-i18next";
//Components
import { Row, Col, DatePicker, Select } from "antd";
import { LngInput, MaskedInput, FormField, CourtSelect } from "components";
import { TermDataWrapper } from "../../DataWrapper";
import { prepareOptions, frontEndDate } from "helpers";
import selections from "constants/selections";
import moment from "moment";

const TermDetail = (props) => {
  const {
    handleChange,
    configurations,
    termType,
    termData,
    OrderErrors,
  } = props;

  const { t: translation } = useTranslation();

  const termWithTribunalFields = {
    CIVIL_COURT_BOND: "CIVIL_COURT_BOND",
    TAX_CREDITS_BOND: "TAX_CREDITS_BOND",
    TAX_ENFORCEMENT_BOND: "TAX_ENFORCEMENT_BOND",
    COURT_OTHERS_BOND: "COURT_OTHERS_BOND",
  };

  const termsWithCustomFields = {
    BIDDING_BOND: "BIDDING_BOND",
    CONSTRUCTION_BOND: "CONSTRUCTION_BOND",
    BUSINESS_SERVICES_BOND: "BUSINESS_SERVICES_BOND",
    SUPPLY_BOND: "SUPPLY_BOND",
  };

  const isPublicContract = termData?.contractType === "PUBLIC_CONTRACT";

  const showProcessNumber = () =>
    termsWithCustomFields[termType] && isPublicContract;

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity)) return configurations[entity];
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;

    if (!isDirect && typeof event === "object") {
      if (event) {
        if (event instanceof Array) value = event;
        else value = event.target.value;
      }
    }

    return handleChange({
      attr: attr,
      value: value,
    });
  };

  const formatToValidDate = (value) => {
    return value !== undefined ? moment(value) : null;
  };
  const onDateChange = (attr) => (event) => {
    let value = event;
    return handleChange({
      attr: attr,
      value: value,
    });
  };

  return (
    <section>
      <TermDataWrapper step="detail-data" field="actionType" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.actionTypeLabel}
          </Col>
          <Col xs={24} md={18}>
            <Select
              value={getValue("actionType")}
              className="gx-w-100"
              placeholder={Translation.appealTypePlaceholder}
              onChange={onChange("actionType")}
            >
              {prepareOptions(selections.actionTypes)}
            </Select>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="contractDate" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.contractDateLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.contractDate"]}>
              <DatePicker
                showToday={true}
                allowClear={false}
                format={frontEndDate}
                value={formatToValidDate(getValue("contractDate"))}
                onChange={onDateChange("contractDate")}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="contractName" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.contractNameLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.contractName"]}>
              <LngInput
                value={getValue("contractName")}
                onChange={onChange("contractName")}
                placeholder={Translation.contractNamePlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="detail-data"
        field="contractNumber"
        type={termType}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.contractNumberLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.contractNumber"]}>
              <LngInput
                value={getValue("contractNumber")}
                onChange={onChange("contractNumber")}
                placeholder={Translation.contractNumberPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="obligation" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.obligationLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.obligation"]}>
              <LngInput
                value={getValue("obligation")}
                onChange={onChange("obligation")}
                placeholder={Translation.obligationPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="installment" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.installmentLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.installment"]}>
              <LngInput
                value={getValue("installment")}
                onChange={onChange("installment")}
                placeholder={Translation.installmentPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="bidDate" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.bidDateLabel}
          </Col>
          <Col xs={24} md={18}>
            <DatePicker
              showToday={true}
              allowClear={false}
              format={frontEndDate}
              value={formatToValidDate(getValue("bidDate"))}
              onChange={onDateChange("bidDate")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="bidName" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.bidNameLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.bidName"]}>
              <LngInput
                value={getValue("bidName")}
                onChange={onChange("bidName")}
                placeholder={Translation.bidNamePlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper step="detail-data" field="bidNumber" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.bidNumberLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.bidNumber"]}>
              <LngInput
                value={getValue("bidNumber")}
                onChange={onChange("bidNumber")}
                placeholder={Translation.bidNumberPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      {showProcessNumber() && (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.processNumberLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.processNumber"]}>
              <LngInput
                value={getValue("processNumber")}
                onChange={onChange("processNumber")}
                placeholder={Translation.processNumberPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      )}
      <TermDataWrapper step="detail-data" field="processNumber" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.processNumberLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["detail.processNumber"]}>
              <MaskedInput
                mask="9999999-99.9999.9.99.9999"
                value={getValue("processNumber")}
                onChange={onChange("processNumber")}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      {termWithTribunalFields[termType] ? (
        <>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
            <Col xs={24} md={6}>
              {translation("order.surety.details.tribunalLabel")}
            </Col>
            <Col xs={24} md={18}>
              <FormField error={OrderErrors["detail.tribunal"]}>
                <LngInput
                  value={getValue("tribunal")}
                  onChange={onChange("tribunal")}
                  placeholder={translation(
                    "order.surety.details.tribunalPlaceholder"
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
            <Col xs={24} md={6}>
              {translation("order.surety.details.courtLabel")}
            </Col>
            <Col xs={24} md={18}>
              <FormField error={OrderErrors["detail.court"]}>
                <LngInput
                  value={getValue("court")}
                  onChange={onChange("court")}
                  placeholder={translation(
                    "order.surety.details.courtPlaceholder"
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </>
      ) : (
        <TermDataWrapper
          step="detail-data"
          field="tribunalAndCourtField"
          type={termType}
        >
          <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
            <CourtSelect
              onChangeCourt={onChange("court")}
              onChangeTribunal={onChange("tribunal")}
              courtValue={getValue("court")}
              tribunalValue={getValue("tribunal")}
              errors={OrderErrors}
            ></CourtSelect>
          </Row>
        </TermDataWrapper>
      )}
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.additionalDataLabel}
        </Col>
        <Col xs={24} md={18}>
          <LngInput
            value={getValue("additionalData")}
            onChange={onChange("additionalData")}
            placeholder={Translation.additionalDataPlaceholder}
          />
        </Col>
      </Row>
      {/* <TermDataWrapper step="detail-data" field="proposalAccepted" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.proposalAcceptedLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("proposalAccepted")}
              onChange={toggleProperty("proposalAccepted")} />
          </Col>
        </Row>
      </TermDataWrapper>

      <TermDataWrapper step="detail-data" field="receiveDraftContract" type={termType}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.receiveDraftContractLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("receiveDraftContract")}
              onChange={toggleProperty("receiveDraftContract")} />
          </Col>
        </Row>
      </TermDataWrapper> */}
    </section>
  );
};

export default TermDetail;
